import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Customer } from '@webcoffee/interfaces';
import { MustMatch } from '../../utils/validators/must-match.validator';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'webcoffee-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule],
})
export class CustomerFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() customer!: Customer | null;
    @Output() customerOutput: EventEmitter<FormGroup> = new EventEmitter();

    subs = new SubSink();

    passwordVisible = false;
    passwordConfirmVisible = false;

    customerFormGroup: FormGroup = new FormGroup(
        {
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            phone: new FormControl(''),
            newsletter: new FormControl(false, Validators.required),
            // password: new FormControl(''),
            // passwordConfirm: new FormControl(''),
            active: new FormControl(true, Validators.required),
        },
        // { validators: MustMatch('password', 'passwordConfirm') as ValidatorFn },
    );

    ngOnInit(): void {
        this.subs.sink = this.customerFormGroup.valueChanges.subscribe((values) => this.customerOutput.emit(this.customerFormGroup));
    }

    ngOnChanges() {
        if (this.customer) {
            this.customerFormGroup.patchValue(this.customer);
        }
    }

    get firstNameControl(): AbstractControl | null {
        return this.customerFormGroup.get('firstname');
    }
    get lastNameControl(): AbstractControl | null {
        return this.customerFormGroup.get('lastname');
    }
    get emailControl(): AbstractControl | null {
        return this.customerFormGroup.get('email');
    }
    get phoneControl(): AbstractControl | null {
        return this.customerFormGroup.get('phone');
    }
    // get passwordControl(): AbstractControl | null {
    //     return this.customerFormGroup.get('password');
    // }
    // get passwordConfirmControl(): AbstractControl | null {
    //     return this.customerFormGroup.get('passwordConfirm');
    // }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
