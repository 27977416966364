<div [formGroup]="customerFormGroup">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Nume</mat-label>
                <input matInput type="text" formControlName="firstname" required />
                <mat-error *ngIf="firstNameControl?.touched && firstNameControl?.invalid && firstNameControl?.errors?.required"> Numele este obligatoriu </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Prenume</mat-label>
                <input matInput type="text" formControlName="lastname" required />
                <mat-error *ngIf="lastNameControl?.touched && lastNameControl?.invalid && lastNameControl?.errors?.required"> Prenumele este obligatoriu </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" required />
                <mat-error *ngIf="emailControl?.touched && emailControl?.invalid && emailControl?.errors?.required"> Emailul este obligatoriu </mat-error>
                <mat-error *ngIf="emailControl?.touched && emailControl?.invalid && emailControl?.errors?.email"> Va rugam introduceti o adresa de email corecta </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput type="text" formControlName="phone" />
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field >
                <mat-label>Parola</mat-label>
                <input matInput #passwordInput [type]="passwordVisible ? 'text' : 'password'" formControlName="password" />
                <mat-icon svgIcon="eye" matSuffix *ngIf="passwordVisible" (click)="passwordVisible = !passwordVisible"></mat-icon>
                <mat-icon svgIcon="eye-slash" matSuffix *ngIf="!passwordVisible" (click)="passwordVisible = !passwordVisible"></mat-icon>
                <mat-hint align="end" aria-live="polite"> {{ passwordInput.value.length }} / {{ passwordStrength.max }} </mat-hint>
            </mat-form-field>
            <mat-password-strength #passwordStrength [password]="passwordControl?.value"></mat-password-strength>
            <mat-password-strength-info
                [hidden]="passwordStrength.strength >= 80 || passwordControl?.value === ''"
                lowerCaseCriteriaMsg="contine cel putin o litera mica"
                upperCaseCriteriaMsg="contine cel putin o majuscula"
                specialCharsCriteriaMsg="contine cel putin un caracter special"
                digitsCriteriaMsg="contine cel putin o cifra"
                minCharsCriteriaMsg="contine cel putin 8 caractere"
                [passwordComponent]="passwordStrength">
            </mat-password-strength-info>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field >
                <mat-label>Confirmare Parola</mat-label>
                <input matInput #passwordConfirmInput [type]="passwordConfirmVisible ? 'text' : 'password'" formControlName="passwordConfirm" />
                <mat-icon svgIcon="eye" matSuffix *ngIf="passwordConfirmVisible" (click)="passwordConfirmVisible = !passwordConfirmVisible"></mat-icon>
                <mat-icon svgIcon="eye-slash" matSuffix *ngIf="!passwordConfirmVisible" (click)="passwordConfirmVisible = !passwordConfirmVisible"></mat-icon>
                <mat-hint align="end" aria-live="polite"> {{ passwordConfirmInput.value.length }} / {{ passwordConfirmStrength.max }} </mat-hint>
                <mat-error *ngIf="passwordConfirmControl?.touched && passwordConfirmControl?.invalid && passwordConfirmControl?.errors?.mustMatch">
                    Parolele trebuie sa fie identice
                </mat-error>
            </mat-form-field>
            <mat-password-strength #passwordConfirmStrength [password]="passwordConfirmControl?.value"></mat-password-strength>
            <mat-password-strength-info
                [hidden]="passwordConfirmStrength.strength >= 80 || passwordConfirmControl?.value === ''"
                lowerCaseCriteriaMsg="contine cel putin o litera mica"
                upperCaseCriteriaMsg="contine cel putin o majuscula"
                specialCharsCriteriaMsg="contine cel putin un caracter special"
                digitsCriteriaMsg="contine cel putin o cifra"
                minCharsCriteriaMsg="contine cel putin 8 caractere"
                [passwordComponent]="passwordConfirmStrength">
            </mat-password-strength-info>
        </div>
    </div> -->
    <div class="row my-4">
        <div class="col-sm-12 col-md-6">
            <mat-checkbox formControlName="newsletter">Abonat la newsletter</mat-checkbox>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-checkbox formControlName="active">Utilizator activ</mat-checkbox>
        </div>
    </div>
</div>
